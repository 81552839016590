<template>
  <div>
    <!-- Filters -->
    {{ productMenuType }}
    <Filters
      v-model="filter"
      :loading.sync="loading"
      @search="handleSearch"
      @clear-filter="handleClearFilter"
    />
    <!--    table-->

    <b-overlay
      id="overlay-bg-product-list"
      class="custom-overlay-icon-top"
      :show="loading"
      variant="white"
      opacity=".5"
      blur="2px"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0 pb-0"
      >
        <div class="py-1 px-2 d-flex justify-content-between">
          <div v-html="'Tìm thấy ' + (totalProducts || 0) + ' kết quả'" />
          <table-column-toggle
            id="refProductListTable"
            :table-columns="tableColumns"
            @update-columns-show="updateTableColumns"
          />
        </div>
        <b-table
          ref="refProductListTable"
          class="position-relative custom-head thead-no-wrap line-height-12 font-small-4"
          responsive
          hover
          primary-key="id"
          show-empty
          small
          empty-text="Không có kết quả nào"
          :items="products"
          :fields="tableColumns"
          @row-clicked="rowClicked"
        >
          <template #cell(id)="data">
            <span class="d-block font-weight-bold text-primary">{{ data.item.id }}</span>
            <div class="product-badge-wrapper">
              <b-badge
                v-if="data.item.is_hot"
                class="product-badge hot"
                variant="danger"
              >HOT
              </b-badge>
              <b-badge
                v-if="data.item.has_corner"
                variant="success"
                class="product-badge corner"
              >GÓC
              </b-badge>
            </div>
          </template>

          <template #cell(image)="data">
            <b-media-aside class="mr-75">
              <b-avatar
                square
                size="64"
                :src="data.item.image ? getUrlFile(data.item.image, data.item.updated_at, showImage160.includes(data.item.image || '') ? '240' : '160') : ''"
                :text="data.item.image ? '' : 'CL'"
                @img-error="() => showImage160.push(data.item.image)"
              />
            </b-media-aside>
          </template>

          <template #cell(title)="data">
            <div
              class="py-50 font-small-3"
              :class="data.item.close_deal_type === 'close-deal' ? 'text-danger' : 'text-primary'"
            >
              <template v-if="data.item.close_deal_type === 'close-deal'">
                [CLOSE DEAL]
              </template>
              {{ data.item.title }}
            </div>
          </template>

          <template #cell(price)="data">
            <div class="text-primary text-nowrap">
              <span class="font-weight-bold">
                {{
                  formatNumber(data.item.input_price)
                }} <small>{{
                  (CURRENCY.find(item => item.value === data.item.currency) || { text: 'usd' }).text.toUpperCase()
                }}</small>
              </span>
              <template v-if="data.item.type_of_payment === 'month'">
                <br>
                / tháng
              </template>
            </div>
          </template>

          <template #cell(nguoi-dang)="data">
            {{ data.item.user.fullname }}
            <div class="product-badge-wrapper">
              <b-badge
                v-if="data.item.is_hidden_phone"
                variant="success"
                class="product-badge hidden-phone"
              >ẨN SỐ
              </b-badge>
            </div>
          </template>

          <template #cell(type)="data">
            <small class="text-nowrap">{{
              (TYPE_PRODUCT.find(item => item.value === data.item.type) || {}).text || ''
            }}</small>
          </template>

          <template #cell(thang-may)="data">
            {{ data.item.elevator ? 'Có' : 'Không' }}
          </template>

          <template #cell(expand_style)="data">
            {{ (EXPAND_STYLE.find(item => item.value === data.item.expand_style) || {}).text || '' }}
            <template v-if="data.item.expand_style_info">
              <br>
              ({{ data.item.expand_style_info }} m)
            </template>
          </template>

          <template #cell(ngay-dang)="data">
            {{ formatDate(data.item.created_at) }}
          </template>

          <template #cell(actions)="data">
            <div class="product-actions-wrapper">
              <b-button
                v-if="ability().can('edit', subject('ProductHired', data.item))"
                variant="outline-primary"
                size="sm"
                class="p-50"
                @click="gotoProductEdit(data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-button>
              <b-button
                v-if="ability().can('delete', subject('ProductHired', data.item))"
                variant="outline-danger"
                size="sm"
                class="p-50"
                @click="openModalConfirmDeleteProduct(data.item)"
              >
                <feather-icon
                  icon="TrashIcon"
                />
              </b-button>
            </div>
          </template>

        </b-table>

        <div class="mb-3">
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>mỗi trang</label>
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalProducts"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      id="modal-create-campaign"
      ref="modal-create-campaign"
      cancel-title="Hủy"
      ok-title="Tạo chiến dịch"
      size="lg"
      :no-close-on-backdrop="true"
      static
      centered
      header-text-variant="primary"
      title="Bắt đầu tạo chiến dịch"
      @ok="handleCreateCampaign"
    >
      <b-card-text>
        <b-row>
          <b-col
            cols="12"
          >
            <dynamic-form
              :id="'product-form-campaignFormName'"
              :required="'required'"
              :value="campaignForm.name"
              :type="'text'"
              :label="'Tên chiến dịch'"
              @update-value="val => campaignForm.name = val"
            />
          </b-col>

          <b-col
            cols="12"
          >
            <dynamic-form
              :id="'product-form-campaignFormAdmin'"
              :required="'required'"
              :value="supporterAdminSelected"
              :options="supporterAdmin"
              :type="'multi-select'"
              :label="'Admin tham gia'"
              value-field="id"
              text-field="fullname"
              :select-default="false"
              @update-value="val => supporterAdminSelected = val"
            />
          </b-col>

          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group label="Ngày bắt đầu">
              <flat-pickr
                id="campaignForm-startTime"
                v-model="campaignForm.start_at"
                class="form-control"
                :config="{ locale: Vietnamese, altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group label="Ngày kết thúc">
              <flat-pickr
                id="campaignForm-endTime"
                v-model="campaignForm.end_at"
                class="form-control"
                :config="{ locale: Vietnamese, altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <br>
        <h5>
          Tổng số <strong class="text-primary">{{ totalProducts }} sản phẩm</strong>
          phân cho <strong class="text-primary">{{ listSupporterAdmin.length }} thành viên</strong>
          <template v-if="totalProducts % listSupporterAdmin.length !== 0">
            (được làm tròn lên)
          </template>
        </h5>

        <hr>

        <small
          v-if="listSupporterAdmin.length"
          class="text-gray mb-1 d-block"
        >(Kéo thả để thay đổi vị trí)</small>

        <draggable
          v-model="listSupporterAdmin"
        >
          <template v-for="(suporter, suporterIdx) in listSupporterAdmin">
            <div
              v-if="suporter"
              :key="'suporteradmin' + suporterIdx + suporter.id"
              class="font-weight-bolder p-50 border-primary cursor-pointer mb-1 user-select-none"
            ><span class="text-primary p-50">{{ suporterIdx + 1 }}.</span>
              {{ suporter.fullname }}:
              <span
                v-if="listSupporterAdmin"
                class="text-primary"
              >
                {{ Math.ceil(totalProducts / supporterAdmin.length) }} sản phẩm
              </span>
            </div>
          </template>
        </draggable>
      </b-card-text>
    </b-modal>

    <actions
      :buttons="actionsButton"
      :loading="actionsLoading"
      @click="handleActionsButton"
    />
  </div>
</template>

<script>
import {
  BAvatar, BCard, BCol, BMediaAside, BPagination, BRow, BTable, VBTooltip, BBadge, BButton, BOverlay,
  BCardText, BFormGroup,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import campaignStoreModule from '@/views/campaign/campaignStoreModule'
import productStoreModule from '@/views/product/productStoreModule'
import TableColumnToggle from '@core/components/TableColumnToggle.vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import flatPickr from 'vue-flatpickr-component'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import useCampaignList from './useCampaignList'
import Filters from './Filters.vue'

export default {
  components: {
    draggable,
    DynamicForm,
    Filters,
    BAvatar,
    BCard,
    BTable,
    TableColumnToggle,
    BPagination,
    vSelect,
    BRow,
    BCol,
    BCardText,
    BMediaAside,
    BBadge,
    BOverlay,
    BButton,
    Actions,
    BFormGroup,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      actionsLoading: false,
      ability,
      subject,
    }
  },
  computed: {
    Vietnamese() {
      return Vietnamese
    },
    actionsButton() {
      return [this.ACTIONS_BUTTON.CREATE_CAMPAIGN]
    },
  },
  watch: {
    supporterAdmin(n) {
      if (!n || !n.length) return
      this.updateListSupporterSelected()
    },
    supporterAdminSelected(n) {
      if (!n || !n.length) return
      this.updateListSupporterSelected()
    },
    products() {
      setTimeout(() => {
        this.$refs.refProductListTable.refresh()
        console.log('refresh table data')
      }, 200)
    },
  },
  created() {
    const { filter } = this.$route.query
    Object.assign(this.filter, JSON.parse(filter || '""'))
    store.dispatch('user/fetchUsers')
      .then(resp => {
        const users = resp.data.data || []
        this.supporterAdmin = users.filter(item => item.role === 'supporter')
      })
    this.filter.product_type = 'townhouse'
    this.fetchProducts()
      .finally(() => {
        this.loading = false
      })
    // this.filter.type_product = 'hired'
    this.filter.formality = 'cho-thue'
    // this.filter.price = 'usd'
    // waiting for watching done
    setTimeout(() => {
      this.created = true
    }, 500)
  },
  methods: {
    gotoProductEdit(product) {
      this.$router.push({
        name: `product-${this.productMenuType}-edit`,
        params: { id: product.id },
      })
    },
    handleActionsButton(btn) {
      if (btn.value === 'create-campaign') {
        this.$refs['modal-create-campaign'].show()

        // set default creator_id
        this.setUserCreateCampaign()

        // set default distribute_users
        this.setDistributeUsersCreateCampaign()
      }
    },
    handleCreateCampaign() {
      this.actionsLoading = true
      this.$call(this.createCampaign(), true)
        .finally(res => {
          this.actionsLoading = false
        })
    },
    openModalConfirmDeleteProduct(product) {
      this.currentProduct = product
      this.$refs['modal-confirm-delete-product'].show()
    },
    handleDeleteProduct() {
      this.$call(this.deleteProduct(this.currentProduct.id), true)
        .then(() => {
          const foundIdx = this.products.findIndex(item => item.id === this.currentProduct.id)
          if (foundIdx !== -1) {
            this.products.splice(foundIdx, 1)
          }
        })
    },
    updateListSupporterSelected() {
      this.listSupporterAdmin = this.supporterAdminSelected.map(id => this.supporterAdmin.find(_item => _item.id === id))
    },
    gotoProductDetail({ id }) {
      const route = this.$router.resolve({
        name: `product-${this.productMenuType}-detail`,
        params: { id },
      })
      window.open(route.href, this.$store.state.app.isDev ? '_self' : '')
    },
    handleSearch() {
      this.$router.replace({
        query: {
          filter: JSON.stringify(this.filter || ''),
        },
      })
      this.fetchProducts()
        .finally(() => {
          this.loading = false
        })
    },
    handleClearFilter(initFilter) {
      this.clearFilter(initFilter)
    },
    updateTableColumns(tableColumns) {
      this.tableColumns = tableColumns
    },
    rowClicked(row) {
      this.gotoProductDetail(row)
    },
  },
  setup() {
    const CAMPAIGN_STORE_MODULE_NAME = 'campaign'
    const PRODUCT_STORE_MODULE_NAME = 'product'
    if (!store.hasModule(CAMPAIGN_STORE_MODULE_NAME)) store.registerModule(CAMPAIGN_STORE_MODULE_NAME, campaignStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CAMPAIGN_STORE_MODULE_NAME)) store.unregisterModule(CAMPAIGN_STORE_MODULE_NAME)
    })

    if (!store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.registerModule(PRODUCT_STORE_MODULE_NAME, productStoreModule)
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_STORE_MODULE_NAME)
    })

    return {
      ...useCampaignList(),
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';

.per-page-selector {
  width: 90px;
}

.custom-head th {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.table .feather {
  min-width: 1rem;
}

.product-badge {
  position: absolute;
  right: 0;
  font-size: .7rem !important;

  &.hot {
    top: .25rem;
  }

  &.corner {
    bottom: .25rem;
  }

  &.hidden-phone {
    top: .25rem;
  }
}

.product-actions-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  > * {
    padding: .25rem !important;
  }
}
</style>
